import React, { useCallback, useEffect, useState } from 'react';
import t from 'react-translate';
import { useSelector, useDispatch } from 'react-redux';

import Tab from 'dashboard/components/tab';
import ClickableContainer from 'components/clickable-container';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';
import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';
import NvDropdown, {
  NvDropdownAlign,
  NvDropdownButtonStyle,
} from 'shared/components/inputs/nv-dropdown';
import NvResponsiveTable, {
  NvResponsiveTableColumn,
} from 'shared/components/nv-responsive-table';
import { ReactComponent as PendingSubmissions } from 'styles/icons/pending-submission-requests.svg';
import { gray2 } from 'styles/global_defaults/colors';
import {
  fetchPendingSubmissions,
  fetchScenariosPendingReview,
} from 'redux/actions/supervisor-dashboard';
import getSupervisorDashboard, {
  getStorePendingSubmissionsData,
} from 'redux/selectors/supervisor-dashboard';
import { VideoPracticeSubmission } from 'redux/schemas/models/video-practice';
import { ScenariosSupervisorDashboard } from 'redux/schemas';
import DashboardLoadingRow, { loadingWrapperStyles } from 'shared/components/dashboard-loading-row';
import { PagedDataQueryParams } from 'redux/create-action-creators';

import { config } from '../../../config/pendo.config.json';

import styles from './styles';
import { DropdownItem, renderIcon, TabName } from './helper';
import PendingSubmissionsRow from './pending-submissions-row';

const initialFilterFetchParams: PagedDataQueryParams['filters'] = {
  scenario_id: undefined,
};

const CourseSupervisorDashboard = () => {
  const DEFAULT_OPTION = {
    id: 0,
    title: t.SUPERVISOR_ADMIN_DASHBOARD.DROPDOWN_DEFAULT(),
    pendingSubmissionsCount: 0,
  };
  const [hasResults, setHasResults] = useState(false);
  const [currentTab, setCurrentTab] = useState<TabName>(
    TabName.PENDING_SUBMISSIONS,
  );
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [pagedFetchParams, setPagedFetchParams] = useState<PagedDataQueryParams>({ filters: initialFilterFetchParams });
  const [selectedScenario, setSelectedScenario] = useState<ScenariosSupervisorDashboard>(DEFAULT_OPTION);
  const [containerRef, setContainerRef] = useState<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const {
    isLoadingPendingSubmissions,
    scenariosPendingReview,
    isLoadingScenariosPendingReview,
  } = useSelector(getSupervisorDashboard);
  const { totalCount } = useSelector(state => state.app.supervisorDashboard);

  const hasUserScrolledDown = useInfiniteScroll(
    containerRef?.firstChild as HTMLDivElement,
    standardSpacing,
    false,
  );

  const handleTabClick = (tabName: TabName) => {
    setCurrentTab(tabName);
  };

  useEffect(() => {
    dispatch(fetchScenariosPendingReview());
  }, [dispatch]);

  const allItems = totalCount ? [DEFAULT_OPTION, ...scenariosPendingReview] : [];

  const onRefChange = useCallback(node => setContainerRef(node), []);

  const tabs = [
    {
      name: TabName.PENDING_SUBMISSIONS,
      title: t.SUPERVISOR_ADMIN_DASHBOARD.TABS.PENDING_SUBMISSIONS(),
      icon: PendingSubmissions,
      count: totalCount,
      color: gray2,
    },
  ];

  const tableColumns: NvResponsiveTableColumn[] = [
    {
      name: t.SUPERVISOR_ADMIN_DASHBOARD.TABLE.COLUMN_HEADERS.SCENARIO_NAME(),
      className: 'scenario-cell',
      gridWidth: '33%',
    },
    {
      name: t.SUPERVISOR_ADMIN_DASHBOARD.TABLE.COLUMN_HEADERS.NAME(),
      className: 'user-cell',
      gridWidth: '33%',
    },
    {
      name: t.SUPERVISOR_ADMIN_DASHBOARD.TABLE.COLUMN_HEADERS.PENDING_REVIEW(),
      className: 'pending-review-cells',
      gridWidth: '33%',
    },
  ];

  const headerCellTop = React.useMemo(() => {
    const scrolledDown = hasResults && !hasUserScrolledDown ? 255 : 215;
    return scrolledDown;
  }, [hasResults, hasUserScrolledDown]);

  const onSelectFilter = (scenario) => {
    if (scenario.id === 0) {
      setPagedFetchParams({ filters: undefined });
    } else {
      setPagedFetchParams({ filters: { scenario_id: scenario.id.toString() } });
    }
    setSelectedScenario(scenario);
    setIsOptionsOpen(false);
  };

  const onOptionsToggle = (isOpen: boolean) => setIsOptionsOpen(isOpen);

  const handleClearSearch = () => {
    setIsOptionsOpen(false);
    setSelectedScenario(DEFAULT_OPTION);
    setPagedFetchParams({ filters: undefined });
  };

  return (
    <div css={styles(headerCellTop, hasUserScrolledDown)}>
      <LoadingWrapper
        keepRendered={false}
        loaderType={LoaderType.PLACEHOLDER}
        isLoaded={!isLoadingPendingSubmissions || !isLoadingScenariosPendingReview}
        css={loadingWrapperStyles}
      >
        <div className='dashboard-header-container'>
          <div className='dashboard-tabs'>
            {tabs.map(tab => (
              <ClickableContainer
                key={tab.name}
                className={`tab-container ${
                  currentTab === tab.name ? 'active' : ''
                }}`}
                onClick={() => handleTabClick(tab.name)}
                data-qa={`course-admin-dashboard-tab-${tab.name.toLowerCase()}`}
              >
                <Tab
                  color={tab.color}
                  count={tab.count}
                  title={tab.title}
                  tooltipText={tab.title}
                  isCompact={hasUserScrolledDown}
                  isSelected={currentTab === tab.name}
                  descriptionItems={[]}
                  renderImage={() => renderIcon(tab.icon)}
                />
              </ClickableContainer>
            ))}
          </div>
          <div className='dashboard-actions'>
            <NvDropdown
              key='manual-btn'
              disabled={allItems.length === 0}
              title={selectedScenario.title}
              buttonStyle={NvDropdownButtonStyle.FORM_SMALL}
              show={isOptionsOpen}
              onToggle={onOptionsToggle}
              toggleDataQa={`${config.pendo.practice.scenariosPendingSubmissions}_dropdown`}
              items={allItems.map(scenario => ({
                id: scenario.id,
                type: 'custom',
                customItem: (
                  <DropdownItem
                    key={scenario.id}
                    title={scenario.title}
                    counter={scenario.pendingSubmissionsCount}
                    onClick={() => onSelectFilter(scenario)}
                    selected={selectedScenario.title === scenario.title}
                    pendo-tag-name={`${config.pendo.practice.scenariosPendingSubmissions}_option_${scenario.id}`}
                  />
                ),
              }))}
              align={NvDropdownAlign.LEFT}
            />
          </div>
        </div>
        <div className='dashboard-content'>
          <div className='dashboard-tab-content' ref={onRefChange}>
            <NvResponsiveTable<VideoPracticeSubmission, {}>
              className='course-admin-dashboard-table'
              columns={tableColumns}
              fetchData={fetchPendingSubmissions}
              fetchParams={{}}
              pagedFetchParams={pagedFetchParams}
              currentPageNameParam='page'
              currentPageSizeNameParam='page_size'
              currentSearchQueryNameParam='text'
              rowComponent={PendingSubmissionsRow}
              rowProps={{ }}
              dataKey='id'
              cacheDataKey='id'
              cacheLookup={getStorePendingSubmissionsData}
              loadingComponent={({ rowIndex }) => (
                <DashboardLoadingRow rowIndex={rowIndex} hideImg />
              )}
              clearSearch={handleClearSearch}
              backgroundColor='transparent'
              noResultsText={null}
              noResultsClearTextDataQA='course-supervisor-dashboard-clear-search-table'
              onResults={setHasResults}
            />
          </div>
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default CourseSupervisorDashboard;
