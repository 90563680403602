import { createReducer } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';

import {
  fetchPendingSubmissions,
  fetchScenariosPendingReview,
} from 'redux/actions/supervisor-dashboard';
import videoPracticeSubmissionsArraySchema from 'redux/schemas/api/supervisor-dashboard';
import { VideoPracticeSubmission } from 'redux/schemas/models/video-practice';
import { initialRootState } from '.';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(fetchPendingSubmissions.fulfilled, (state, action) => {
      state.app.supervisorDashboard.isLoadingPendingSubmissions = false;
      const normalizedData = normalize<
      VideoPracticeSubmission,
      { videoPracticeSubmissions: { [key: string]: VideoPracticeSubmission } }
      >(action.payload.response, videoPracticeSubmissionsArraySchema);
      state.app.supervisorDashboard.pendingSubmissions = {
        ...state.app.supervisorDashboard.pendingSubmissions,
        ...normalizedData.entities.videoPracticeSubmissions,
      };
      if (!action.payload.hasScenarioId) {
        state.app.supervisorDashboard.totalCount = action.payload.totalCount;
      }
    })
    .addCase(fetchScenariosPendingReview.pending, state => {
      state.app.supervisorDashboard.isLoadingScenariosPendingReview = true;
    })
    .addCase(fetchScenariosPendingReview.rejected, state => {
      state.app.supervisorDashboard.isLoadingScenariosPendingReview = false;
    })
    .addCase(fetchScenariosPendingReview.fulfilled, (state, action) => {
      state.app.supervisorDashboard.isLoadingScenariosPendingReview = false;
      state.models.supervisorDashboard.scenariosPendingReview = action.payload;
    });
});
