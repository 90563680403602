import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AngularContext, AngularServicesContext } from 'react-app';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { css } from '@emotion/react';

import { useQuery } from 'shared/hooks/use-query';
import { getCurrentUser } from 'redux/selectors/users';
import { warning, white } from 'styles/global_defaults/colors';
import { PracticeRoomTab, RecordingFormat, SubmissionTab } from 'redux/schemas/models/video-practice';
import { getSubmission, getScenario } from 'redux/selectors/video-practice';
import { setPracticeRoomState } from 'redux/actions/video-practice';
import { useAppDispatch } from 'redux/store';
import { config } from '../../../../../config/pendo.config.json';
import { ActionTypes, PracticeSubmissionContext } from '../utils';

export enum ReviewStatus {
  NO_APPROVAL = 'no_approval',
  REQUESTED = 'requested',
  REVIEWED = 'reviewed',
}

const styles = () => css`
  &.review-tag-container {
    position: absolute;
    z-index: 10;

    &--video {
      left: -32px;
      top: 16px;
    }

    &--audio {
      left: -36px;
      top: -8px;
    }

    button {
      opacity: 1;
      padding: 5px 14px;
    }
    .review-tag-active {
      background-color: ${warning};
      color: ${white};
      border: 0;

      &:hover {
        background-color: ${warning}e5;
      }
    }
  }
`;

const RequestReviewCTA = () => {
  const [{ submissionId, skillTags, scenarioId }, practiceSubmissionDispatch] = useContext(PracticeSubmissionContext);
  const { reviewStatus, hasInsightsEnabled, reviewerUserId, user: submissionUser } = useSelector(state => getSubmission(state, submissionId));
  const { recordingFormat, hasMentorsInCoursesUsedIn } = useSelector(state => getScenario(state, scenarioId));
  const query = useQuery();
  const dispatch = useAppDispatch();
  // Request Review CTA configurations
  const queryUserId = query?.user;
  const hasFeedbackBy = !!query?.feedbackBy;
  const { injectServices } = useContext(AngularContext);
  const { $state } = useContext(AngularServicesContext);
  const [$, CurrentUserManager] = injectServices(['$state', 'CurrentUserManager']);
  const hasMentors = CurrentUserManager?.currentUserCourse?.mentors?.length > 0 || hasMentorsInCoursesUsedIn || CurrentUserManager.isSupervisor();
  const user = useSelector(getCurrentUser);
  const allowCurrentUserToSeeCTA = (query.selected === PracticeRoomTab.MY_PRACTICE || Number(queryUserId) === user.id || hasFeedbackBy || CurrentUserManager.isSupervisor());
  const showRequestReviewCTA = hasInsightsEnabled && skillTags.length > 0 && allowCurrentUserToSeeCTA && hasMentors;

  // Control the CTA label and disabled state based on the review status
  const showRequestReviewCTAConfig = useMemo(() => {
    if (reviewStatus === ReviewStatus.REQUESTED) {
      return {
        label: t.PRACTICE_ROOM.INSIGHTS.REQUEST_REVIEW.PENDING_REVIEW_CTA(),
        disabled: true,
      };
    }
    if (reviewStatus === ReviewStatus.REVIEWED) {
      return {
        label: t.PRACTICE_ROOM.INSIGHTS.REQUEST_REVIEW.REVIEW_RECEIVED_CTA(),
        disabled: false,
      };
    }
    return undefined;
  }, [reviewStatus]);

  const onClickSeeReview = () => {
    if (CurrentUserManager.isSupervisor()) {
      dispatch(setPracticeRoomState({
        scenarioId,
        submissionId,
        selectedTab: PracticeRoomTab.GALLERY,
        userId: submissionUser.id,
        selectedSubmissionTab: SubmissionTab.AUTHOR_FEEDBACK,
      }));
      practiceSubmissionDispatch({ type: ActionTypes.SET_SELECTED_VIEW, payload: SubmissionTab.AUTHOR_FEEDBACK });
    } else {
      dispatch(setPracticeRoomState({
        scenarioId,
        submissionId,
        selectedTab: PracticeRoomTab.MY_PRACTICE,
        userId: submissionUser.id,
        selectedSubmissionTab: SubmissionTab.ALL_FEEDBACK,
        feedbackBy: reviewerUserId,
      }));
      practiceSubmissionDispatch({ type: ActionTypes.SET_SELECTED_VIEW, payload: SubmissionTab.ALL_FEEDBACK });
    }
  };

  const URL = useMemo(() => {
    if (showRequestReviewCTAConfig?.disabled) {
      return null;
    }
    if (CurrentUserManager.isSupervisor()) {
      return `/#!/practice-room/${scenarioId}/?user=${submissionUser.id}&submission=${submissionId}&submissionView=${SubmissionTab.AUTHOR_FEEDBACK}&selected=${PracticeRoomTab.GALLERY}&galleryMode=true`;
    }
    return `/#!/practice-room/${scenarioId}/?submission=${submissionId}&submissionView=${SubmissionTab.ALL_FEEDBACK}&feedbackBy=${reviewerUserId}&selected=${PracticeRoomTab.MY_PRACTICE}&galleryMode=true`;
  }, [CurrentUserManager, reviewerUserId, scenarioId, showRequestReviewCTAConfig?.disabled, submissionId, submissionUser.id]);

  return (
    <div
      css={styles}
      className={`review-tag-container ${
        recordingFormat === RecordingFormat.VIDEO
          ? 'review-tag-container--video'
          : 'review-tag-container--audio'
      }`}
    >
      {showRequestReviewCTAConfig && showRequestReviewCTA && (
        <Button
          variant='primary'
          href={URL}
          size='lg'
          disabled={showRequestReviewCTAConfig.disabled}
          className={`d-flex align-items-center ml-4 ${
            !showRequestReviewCTAConfig.disabled ? 'review-tag-active' : ''
          }`}
          onClick={onClickSeeReview}
          pendo-tag-name={config.pendo.practice.requestReviewsCta}
          aria-label={showRequestReviewCTAConfig.label}
        >
          <div className='icon icon-course-mentees mr-2' />
          {showRequestReviewCTAConfig.label}
        </Button>
      )}
    </div>
  );
};

export default RequestReviewCTA;
