import { schema } from 'normalizr';
import { VideoPracticeSubmission } from '../models/video-practice';

const videoPracticeSubmissionSchema = new schema.Entity<VideoPracticeSubmission>('videoPracticeSubmissions', {}, {
  idAttribute: 'id',
});

const videoPracticeSubmissionsArraySchema = [videoPracticeSubmissionSchema];

export default videoPracticeSubmissionsArraySchema;
